import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { BackgroundContainer, StyledContainer } from "../../Styled/Container"
import WishlistListItem from "./WishlistListItem"
import { withWishlistList } from "./withWishlistList"

const Wrapper = tw.div`
  flex flex-wrap -mx-1-6 pb-9-6 md:pb-7-2
`

const Card = styled(WishlistListItem)`
  ${tw`w-full md:w-1/3 lg:w-1/4`}
`

const WishlistList = withWishlistList(({ products, handleDelete }) => {
  return (
    <BackgroundContainer background={`light`}>
      <StyledContainer>
        <Wrapper>
          {products.map((product, index) => (
            <Card key={index} product={product} handleDelete={handleDelete} />
          ))}
        </Wrapper>
      </StyledContainer>
    </BackgroundContainer>
  )
})

export default WishlistList
