import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { navigate } from "gatsby"

import { withWishlist } from "./withWishlist"
import { BackgroundContainer } from "../../Styled/Container"
import { StyledButton } from "../../Styled/Button"
import PageTitle from "../../PageTitle/PageTitle"
import Share from "./WishlistShare"
import WishlistList from "./WishlistList"
import { Sections } from "../../Sections/Sections"

const Container = tw.div`
  relative max-w-xl mx-auto
`

const ShareWrapper = tw.div`
  absolute bottom-2-4 left-2-4 md:bottom-auto md:left-auto md:top-11 md:right-7-2 z-10
`

const ButtonWrapper = tw.div`pt-2-4 md:pt-3-2 pb-5-6 md:pb-7-2 w-full flex justify-center`

const Button = styled(StyledButton)`
  ${tw`max-w-41 w-full`}
`

const Text = tw.p`
  text-16 md:text-22
`

export const Wishlist = withWishlist(({ title, emptyTitle, paths, count, startShopping, page, location, shareUrl, handleShare }) => (
  <>
    <BackgroundContainer background={`light`}>
      <Container>
        {count > 0 && (
          <ShareWrapper>
            <Share link={shareUrl} type={`wishlist`} handleShare={handleShare} />
          </ShareWrapper>
        )}
        <PageTitle title={count === 0 ? emptyTitle : title} paths={paths}>
          {count === 0 ? (
            <ButtonWrapper>
              <Button height={`56`} textSize={`16`} colour={`green`} onClick={() => navigate(startShopping?.link)}>
                {startShopping?.title}
              </Button>
            </ButtonWrapper>
          ) : (
            <Text>
              {count} {count === 1 ? `product` : `products`}
            </Text>
          )}
        </PageTitle>
        {count > 0 ? <WishlistList location={location} /> : null}
      </Container>
    </BackgroundContainer>
    {count === 0 && page?.content ? <Sections page={page} /> : null}
  </>
))
