import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { navigate, Link } from "gatsby"

import { withWishlistListItem } from "./withWishlistListItem"
import Image from "../../Image/Image"
import FormDrapdown from "../../Form/FormDropdown"
import AddToCart from "../../Product/AddToCart/AddToCart"
import Price from "../../Price/Price"
import Icon from "../../Icon"

const Wrapper = tw.div`
  px-1-6 py-2-4 flex flex-wrap border-t border-beige md:border-0 relative
`

const ImageWrapper = tw.div`
  w-1/3 md:w-full relative
`

const Badge = styled.span`
  ${tw`absolute left-0-8 md:left-1-6 top-0-8 md:top-1-6 text-12 md:text-18 leading-2 md:leading-2.25 px-1-6`}
  ${({ sale }) => (sale ? tw`md:px-3 text-lightest bg-dark` : tw`text-dark bg-light`)}
`

const Close = styled(Icon)`
  ${tw`absolute text-grey cursor-pointer z-10`}
  ${({ desktop }) => (desktop ? tw`hidden md:block right-1-6 top-1-6` : null)}
  ${({ mobile }) => (mobile ? tw`md:hidden right-0-8 top-0-8` : null)}
`

const DetailsWrapper = tw.div`
  w-2/3 md:w-full pl-2-4 md:pl-0
`

const Details = tw.div`
  flex flex-wrap md:py-1-6
`

const Title = styled(Link)`
  ${tw`flex-1 pr-3-6 mb-0-8 md:mb-0`}
`

const Text = styled.p`
  ${tw`text-14 md:text-16 leading-1.75 md:leading-1.31 inline `}
  ${({ bold }) => (bold ? tw`font-medium` : null)}
`

const SelectorWrapper = tw.div`
  mt-2-4 md:mt-0
`

const DropdownSelector = styled(FormDrapdown)`
  ${tw`mb-0-8`}
`

const WishlistListItem = withWishlistListItem(
  ({ id, title, subTitle, badge, link, image, hoverImage, notify, selectedVariant, options, handleChange, handleDelete, className }) => {
    return (
      <Wrapper className={className}>
        <Close mobile name={`close`} size={`xsmall`} />
        <ImageWrapper>
          <Image
            image={image?.src}
            hoverImage={hoverImage?.src}
            ratio={`1/1`}
            onClick={e => {
              e.stopPropagation()
              navigate(link)
            }}
          />
          {badge && <Badge sale={badge.toLowerCase() === `sale`}>{badge}</Badge>}
          <Close desktop name={`close`} size={`small`} onClick={() => handleDelete(id)} />
        </ImageWrapper>
        <DetailsWrapper>
          <Details>
            <Title to={link}>
              <Text bold>{title}&nbsp;</Text>
              <Text>{subTitle}</Text>
            </Title>
            <Price price={selectedVariant?.priceV2} compareAtPrice={selectedVariant?.compareAtPriceV2} layout={`wishlist`} />
          </Details>
          <SelectorWrapper>
            {options && (
              <DropdownSelector name={`selector`} options={options} placeholder={`Select size`} handleChange={handleChange} layout={`wishlist`} />
            )}
            <AddToCart notify={notify} selectedVariant={selectedVariant} wishlist />
          </SelectorWrapper>
        </DetailsWrapper>
      </Wrapper>
    )
  }
)

WishlistListItem.props = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  badge: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  hoverImage: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  notify: PropTypes.bool,
  selectedVariant: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      available: PropTypes.bool,
    })
  ),
  handleChange: PropTypes.func,
  className: PropTypes.string,
}

export default WishlistListItem
