import React from "react"

import { useWishlist } from "../../../hooks/useWishlist"

export const withWishlist = Component => ({ name = "Wishlist", page, location }) => {
  const { wishlist, shareUrl, shareWishlist } = useWishlist()

  const title = page?.title
  const emptyTitle = page?.emptyTitle
  const startShopping = page?.startShopping

  const paths = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Wishlist",
    },
  ]

  const handleShare = () => shareWishlist

  Component.displayName = name
  return (
    <Component
      title={title}
      emptyTitle={emptyTitle}
      paths={paths}
      count={wishlist?.length}
      startShopping={startShopping}
      page={page}
      location={location}
      shareUrl={shareUrl}
      handleShare={handleShare}
    />
  )
}
