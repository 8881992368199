import React from "react"

import { useCore } from "../../../hooks/useCore"
import { useApp } from "../../../hooks/useApp"
import { useWishlist } from "../../../hooks/useWishlist"

export const withWishlistShare = Component => ({ name = "WishlistShare" }) => {
  const { wishlist } = useWishlist()
  const {
    helpers: { encodeBase64 },
  } = useCore()

  const {
    config: {
      app,
      settings: {
        routes,
        socialMedia: { sharing },
      },
    },
  } = useApp()

  const socialShare = (type, link) => {
    switch (type) {
      case "email":
        window.open(`mailto:?subject=Check my wishlist on Bared footwear&body=${link}`, "_blank")
        break
      case "facebook":
        window.open(`${sharing.facebook}${encodeURIComponent(link)}`, "facebook-popup", "height=400,width=600")
        break
      default:
        break
    }
  }

  const handleShare = type => {
    const string = wishlist?.map(item => `${item.handle}:${item.selectedSku}`).join("|")
    const url = `${app?.url}${routes.WISHLIST}?id=${encodeBase64(string)}`
    socialShare(type, url)
  }

  Component.displayName = name
  return <Component loading={false} handleShare={handleShare} />
}
