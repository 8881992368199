import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

import { withWishlistShare } from "./withWishlistShare"
import Icon from "../../Icon"

const Wrapper = tw.div`
  flex flex-row items-center
`

const Button = tw.button`
  focus:outline-none
`

const Title = tw.span`mr-0-8`

const WishlistShare = withWishlistShare(({ link, handleShare }) => {
  return (
    <Wrapper>
      <Title>Share</Title>
      <Button onClick={() => handleShare(`email`)}>
        <Icon name={`email`} size={`sm`} />
      </Button>
      <Button onClick={() => handleShare(`facebook`)}>
        <Icon name={`facebook`} size={`sm`} />
      </Button>
    </Wrapper>
  )
})

WishlistShare.propTypes = {
  link: PropTypes.string,
  handleShare: PropTypes.func,
}

export default WishlistShare
