import React, { useEffect } from "react"

import { useVariant } from "../../../hooks/useVariant"
import { useApp } from "../../../hooks/useApp"

export const withWishlistListItem = Component => ({ name = "WishlistListItem", product, handleDelete, className }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { id, handle, tags } = product
  const notify = tags?.some(tag => tag.includes(`notify`))

  const { selectedVariant, setSelectedVariant, dropdownOptions, handleOptionChange } = useVariant(product?.variants, notify)

  useEffect(() => {
    if (product?.variants?.length > 0) {
      setSelectedVariant(product.variants[0])
    }
  }, [product, setSelectedVariant])

  const badge = tags?.find(tag => tag.includes(`badge`))?.split(":")[1]

  Component.displayName = name

  return (
    <Component
      id={id}
      title={product?.title?.split(" ")[0]}
      subTitle={product?.title?.split(" ")[1]}
      badge={badge}
      link={`${routes.PRODUCT}/${handle}`}
      image={product?.images[0]}
      hoverImage={product?.images[1]}
      notify={notify}
      selectedVariant={selectedVariant}
      options={dropdownOptions}
      handleChange={handleOptionChange}
      handleDelete={handleDelete}
      className={className}
    />
  )
}
